<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import  ConfirmActionDialog  from "@/components/ConfirmActionDialog";
import  Lecture  from "@/components/Chapters/Lecture";
import userService from "@/helpers/userService";
import errorHandler from '@/helpers/errorHandler';
import moment from "moment";
import ExportExcel from '../../../components/exportExcel.vue';

/**
 * Starter component
 */
export default {
  page: {
    title: "Lectures list",
  },
  head() {
    return {
      title: `Lectures`,
    };
  },
  components: {
    Layout,
    PageHeader,
    ConfirmActionDialog,
    Lecture,
    ExportExcel
  },
  async created() {
    await this.$store.dispatch("chapter/getAllChaptersByYear",userService.methods.userYear()).then( ()=>{}).catch( err => {
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
    await this.$store.dispatch("balance/getPurchaseLogs",userService.methods.userID()).then( ()=>{}).catch( err => {
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
  },
  computed: {
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    lectures() {
      return this.$store.getters["chapter/allChapters"];
    },
    logs() {
      return this.$store.getters["balance/allLogs"];
    },
    rows() {
      return this.logs.length;
    }
  },
  filters:{
    formatDate(value){
      if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
      }
    }
  },
  methods: {
    RemoveRecord:function(){
     this.$store.dispatch("chapter/purchaseLecture",this.recordId).then(async (token)=>{
      errorHandler.methods.successful('Lecture purchased successfully!')
      await this.$store.dispatch("balance/getPurchaseLogs",userService.methods.userID()).then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      });
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('deleteRecord')
    },
     showDeleteModal(id,free) {
        if(!free){
            this.$bvModal.show('deleteRecord')
            this.recordId = id
        }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  data: () => ({    totalRows:0,
    recordId: null,
    title: "Lectures List",
    items: [
      {
        text: "Work Center",
        href: "/"
      },
      {
        text: "Lectures",
        href: "/chapters"
      }
    ],
    perPage: 20,
    currentPage: 1,
    pageOptions: [10, 20, 30],
    filter: "",
    sortBy: "number",
    sortDesc: false,
    filterOn: ["number","name","description"],
    fields: [
      {
        key: "timestamp",
        label: "Time",
        sortable: true,
        selected: true
      },
      {
        key: "chapter",
        label: "Lecture",
        sortable: true,
        selected: true
      },
      {
        key: "purchase_type",
        label: "Attended",
        sortable: true,
        selected: true
      },
    ]
  })
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h2>My Lectures</h2>
            <div class="row mb-2">
                <div class="col-xl-4 col-md-6 col-sm-12" v-for="item in lectures" :key="item.id">
                 <div @click="showDeleteModal(item.id,item.free)">

                    <Lecture :title="item.name" :icon="'fas fa-suitcase'" :link="'/students/lectures/'+item.id" :desc="item.description" :color="'#bbdefb'" :free="item.free || item.purchased"/>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display 
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    > Records
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                <export-excel :jsonData="logs" v-if="$store.getters['user/role'] == 0"></export-excel>
                  <label class="d-inline-flex align-items-center mt-2">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <b-dropdown
                    id="dropdown-offset"
                    offset="250"
                    variant="link"
                    class="btn-icon-only"
                  >
                    <template #button-content>
                      <i class="fas fa-th-large iconGray"></i>
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox
                        v-for="(field, index) in fields"
                        :key="index"
                        class="mb-3"
                        v-model="field.selected"
                        :disabled="field.disabled"
                        >{{ field.label }}</b-form-checkbox
                      >
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </div>
              <!-- End search -->
            </div>
            <b-table
              table-class="table table-centered w-100 table-hover"
              thead-tr-class="bg-light"
              tbody-tr-class="clickableRow"
              :items="logs"
              :fields="tableFields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
            <template v-slot:cell(timestamp)="data">
              {{ data.item.timestamp | formatDate }}
            </template>
            <template v-slot:cell(chapter)="data">
              {{ data.item.chapter.name }}
            </template>
            </b-table>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="(filter == '' )?rows:totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="deleteRecord" size="md" title="Warning Action">
        <ConfirmActionDialog/>
        <template #modal-footer>
          <b-button variant="outline-success" @click="RemoveRecord()">Purchase</b-button>
          <b-button variant="outline-danger" @click="$bvModal.hide('deleteRecord')">Cancel</b-button>
        </template>
    </b-modal>
  </Layout>
</template>
